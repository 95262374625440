
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { readMC4File } from "@/utils/batchDownload";
import * as qiniu from 'qiniu-js';

@Component({
    name: 'UploadMC4App',
})
export default class UploadMC4App extends Vue {
    @Prop(Number) private dbType!: 0 | 1;   // 数据库类型   0:'oracle' | 1:'pg';
    @Prop(String) private packagePath!: string;
    private fileList: any = [];
    private subscribe: any = null;
    private percentage = 0;
    private loading: boolean = false;
    private pgError: string = '';
    private formData: any = {
        packageIdentifier: null,
        packageVersion: null,
        packagePath: null,
        packageCreateTime: null,
    };
    @Watch('packagePath')
    private watchPackagePath(val: string) {
        if (val) {
            this.fileList = [{
                name: this.getZipPath(val),
                url: val,
            }];
        } else {
            this.fileList = [];
        }
    }
    @Emit('handleChange')
    private handleChange(status: string = 'done', errMsg?: string) {
        return ({
            status, // 'uploading | done | error'
            errMsg,
            data: this.formData,
        });
    }

    private mounted() {
        if (this.packagePath) {
            this.fileList = [{
                name: this.getZipPath(this.packagePath),
                url: this.packagePath,
            }];
        } else {
            this.fileList = [];
        }
    }
    private onRemove(status: string, errMsg?: string) {
        this.fileList = [];
        this.formData.packagePath = '';    // 包地址
        this.formData.packageIdentifier = '';    // 包标识
        this.formData.packageVersion = '';    // 包版本
        this.formData.packageCreateTime = '';   // 包创建时间
        this.percentage = 0;
        if (this.subscribe) {
            this.subscribe.unsubscribe(); // 上传取消
            this.loading = false;
        }
        this.handleChange(status, errMsg);
    }
    // 上传应用包之前校验文件类型、大小
    private beforeUploadZIP(file: any) {
        const fileType = file.type;
        const fileName = file.name;
        // 根据后缀判断文件类型
        let fileSuffix = "";
        try {
            const fileArr = fileName.split(".");
            fileSuffix = fileArr[fileArr.length - 1];
        } catch (err) {
            fileSuffix = "";
        }
        const isZip = (
            fileType === 'application/x-zip-compressed'
            || fileType === 'application/zip'
        );
        const isLt900M = file.size / 1024 / 1024 < 900;
        if (!isLt900M) {
            this.$message.error('上传文件大小不能超过 900MB!');
            return false;
        }
        if (!isZip) {
            this.$message.error('请上传.zip格式文件!');
            return false;
        }
    }
    // 解析应用包
    private analyzingZIP(data: any) {
        // const param = new FormData();
        // param.append('file', data.file);
        this.fileList = [];
        this.pgError = '';
        this.loading = true;
        this.handleChange('uploading', '');
        readMC4File(data.file).then((res: any) => {
            if (!res['app.json'] || !res['meta.json']) {
                this.$message.error('缺少部分必要文件，解析失败！');
                this.loading = false;
                this.onRemove('error');
            } else {
                const appJson = JSON.parse(res['app.json']);
                const metaJson = JSON.parse(res['meta.json']);
                if (this.dbType !== appJson.appDbType) {
                    // 应用包类型不正确
                    this.pgError = '应用包类型不正确';
                    this.loading = false;
                    this.onRemove('error', '应用包类型不正确');
                } else {
                    this.formData.packageIdentifier = appJson.id;    // 包标识
                    this.formData.packageVersion = metaJson.mc_version;    // 包版本
                    this.formData.packageCreateTime = metaJson.create_time;    // 包创建时间
                    this.uploadZIP(data.file);  // 上传应用包
                }
            }
        }).catch((err: any) => {
            this.$message.error(err.message || '解析失败！');
            this.loading = false;
            this.onRemove('error');
        });
    }
    private getToken(fileName: string) {
        return this.$httpService.getData({
            key: fileName,
            notLoading: true,
        }, '/apiProxy/api/frontend/file/upload-mc4-app-package-token');
    }
    private getZipPath(path: string) {
        const arr = path.split('/');
        const fileName = arr[arr.length - 1];
        return fileName;
    }
    // 七牛-上传文件
    private upFileByQiniu(file: any, json: any) {
        const putExtra = {};
        const config = {
            useCdnDomain: true,
            chunkSize: 50,
        };
        const observable = qiniu.upload(file, json.key, json.token, putExtra, config);
        const observer = {
            next: (res: any) => {
                this.percentage = Number(res.total.percent.toFixed(1));
                if (res.total.percent === 100) {
                    this.percentage = 0;
                    this.formData.packagePath = json.path;    // PG包地址
                    this.fileList = [{
                        name: this.getZipPath(json.path),
                        url: json.path,
                    }];
                    this.subscribe = null;
                    this.loading = false;
                    this.handleChange('done');
                }
            },
            error: (err: any) => {
                this.subscribe = null;
                this.loading = false;
                this.$message.error(err.message || '上传失败！');
                this.onRemove('error');
            },
        };
        this.subscribe = observable.subscribe(observer); // 上传开始
    }
    private uploadZIP(file: any) {
        this.getToken(file.name).then((res: any) => {
            this.upFileByQiniu(file, res);
        }).catch(() => {
            this.loading = false;
            this.onRemove('error');
        });
    }
}
