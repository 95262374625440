
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import ContentTitle from '@/components/ContentTitle.vue';
import Pictures from "../../saasManage/components/Pictures.vue";
import AddDiscount from '../../saasManage/components/addDiscount.vue';
import UploadMC4App from './UploadMC4App.vue';
import UE from "@/components/RichTextEditor/ueditor.vue";
import { ElForm } from 'element-ui/types/form';

@Component({
    name: 'AddSaas',
    components: {
        ContentTitle,
        Pictures,
        AddDiscount,
        UploadMC4App,
        UE,
    },
})
export default class AddSaas extends Vue {
    @Prop(String) private id!: string;
    @Prop(Number) private appStatus!: any;  // -1: 审核不通过；0: 编辑中；1: 待审核；2: 已上架；3: 已下架；
    @Prop({
        type: String,
        default: 'add',
    }) private pageType!: string;   // add; edit; info;
    private contentTitle: string = '提交应用';
    private baseUrl: string = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private isView: boolean = false;
    private initUE: boolean = false;
    private formData: any = {
        name: '',
        invoiceName: '',
        industryTypes: [],
        icon: '',
        pictures: [],
        deployType: [],
        priceItems: [{
            type: 1,
            price: '',
            unit: '套',
            existFlag: true,
        }, {
            type: 2,
            price: '',
            existFlag: false,
        }, {
            type: 3,
            price: '',
            existFlag: false,
        }],
        mcVersion: '3.0',
        databaseTypes: [1, 2],
        pgPackagePath: null,    // pg包地址
        pgPackageIdentifier: null,    // pg包标识
        pgPackageVersion: null,    // pg包版本
        pgPackageCreateTime: null,  // pg包创建时间
        oraclePackagePath: null,    // ORACLE包地址
        oraclePackageIdentifier: null,    // ORACLE包标识
        oraclePackageVersion: null,    // ORACLE包版本
        oraclePackageCreateTime: null,  // ORACLE包创建时间
        description: '',
        introduction: '',
        discountItems: [],
    };
    private rules: any = {
        name: [
            { required: true, message: '请输入商品名称', trigger: 'change' },
        ],
        industryTypes: [
            { required: true, message: '请选择行业类型', trigger: 'change' },
        ],
        deployType: [
            { required: true, message: '请选择部署方式', trigger: 'change' },
        ],
        priceItems: [
            { validator: (rule: any, value: any, callback: any) => {
                const existFlag = value.findIndex((item: any) => item.existFlag);
                if (existFlag < 0) {
                    callback('至少选择一种售价规则');
                } else {
                    callback();
                }
            }, trigger: 'change' },
        ],
        icon: [
            { required: true, message: '请上传商品ICON', trigger: 'change' },
        ],
        pictures: [
            { required: true, message: '请上传轮播图', trigger: 'change' },
        ],
        mcVersion: [
            { required: true, message: '请选择平台版本', trigger: 'change' },
        ],
        databaseTypes: [
            { required: true, message: '请至少选择一种数据库', trigger: 'change' },
        ],
        // pgPackagePath: [
        //     { required: true, message: '请上传pg应用包', trigger: 'change' },
        // ],
        // oraclePackagePath: [
        //     { required: true, message: '请上传Oracle应用包', trigger: 'change' },
        // ],
        description: [
            { required: true, message: '请输入详情', trigger: 'change' },
        ],
        introduction: [
            { required: true, message: '请输入商品简介', trigger: 'change' },
        ],
        // mobile: [
        //     { required: true, message: '请输入手机号', trigger: 'change' },
        //     { validator: this.validateMobile, trigger: 'change' },
        // ],
    };
    private oracleError = '';
    private pgError = '';
    private pgUploading: boolean = false;
    private oracleUploading: boolean = false;
    private menuFileList: any = [];
    private showDialog: boolean = false;
    private menuList: any = []; // 套件清单
    private discountRules: any = {
        monthlyList: [],
        yearlyList: [],
    };    // 折扣规则
    private industryTypes: any = [];    // 行业类型
    // 添加折扣规则
    private discountVisible: boolean = false;
    private discountInfo: any = {};
    private discountOptions: any = [];
    // UE
    private ueditorConfig: any = {
        autoHeightEnabled: false,
        autoFloatEnabled: false,
        initialFrameHeight: 300,
        initialFrameWidth: null, // 关闭字数统计
        wordCount: false, // 关闭elementPath
        elementPathEnabled: false,
        enableAutoSave: false,
        toolbars: [[
            'fullscreen', 'source', '|', 'undo', 'redo', '|',
            'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
            'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
            'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
            'directionalityltr', 'directionalityrtl', 'indent', '|',
            'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
            'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
            'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'attachment', 'map', 'insertframe', 'insertcode', 'webapp', 'pagebreak', 'template', 'background', '|',
            'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
            'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
            'print', 'preview', 'searchreplace', 'help', 'drafts',
        ]],
    };
    private formRef: any = null;
    private monthlyList(discountItems: any) {
        discountItems = discountItems || [];
        return discountItems.filter((item: any) => item.number < 12);
    }
    private yearlyList(discountItems: any) {
        discountItems = discountItems || [];
        return discountItems.filter((item: any) => item.number >= 12);
    }
    private validatePrice(rule: any, value: any, callback: any, priceItem: any) {
        const moneyVlid = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
        if (!priceItem.existFlag) {
            callback();
        } else if (value === null || value === '') {
            callback(new Error('价格不能为空'));
        } else if (value !== null && value !== '' && value !== '0' && !moneyVlid.test(value)) {
            callback(new Error('价格格式不正确'));
        } else if ((priceItem.type === 2 || priceItem.type === 3) && (parseInt(value, 10) <= 0)) {
            callback(new Error('当前售价规则价格不能为0元'));
        } else  {
            callback();
        }
    }
    private validateLicenses(rule: any, value: any, callback: any, priceItem: any, field: string) {
        const numVlid = /(^[1-9]\d*$)/;
        if (!priceItem.existFlag) {
            callback();
        } else if (value !== null && value !== '' && !numVlid.test(value)) {
            callback(new Error('数量格式不正确'));
        } else if (field === 'minimumLicenses' && (parseInt(value, 10)
            < parseInt(priceItem.complimentaryLicenses, 10))) {
            callback(new Error('起购数不能少于授权数'));
        } else  {
            callback();
        }
    }
    @Watch('formData.mcVersion')
    private mcVersionChange(val: any) {
        this.oracleError = '';
        this.pgError = '';
        this.clearValidate(['oraclePackagePath', 'pgPackagePath']);
    }
    @Watch('formData.databaseTypes')
    private databaseTypesChange(val: any) {
        this.pgError = '';
        this.oracleError = '';
        this.clearValidate(['pgPackagePath', 'oraclePackagePath']);
    }
    private formatterDiscount(num: number) {
        let timeUnit = num + '个月';
        if (num >= 12) {
            timeUnit = (num / 12) + '年';
        }
        return timeUnit;
    }
    // 是否永久
    private changeExistFlag(val: boolean, priceIndex: number) {
        if (this.formData.priceItems[priceIndex].type === 1) {
            const priceItems = this.formData.priceItems;
            this.clearValidate(['priceItems'
                , `priceItems.${priceIndex}.price`
                , `priceItems.${priceIndex}.unit`]);
        } else {
            this.clearValidate(['priceItems', `priceItems.${priceIndex}.price`]);
        }
    }
    // 清除指定表单校验
    private clearValidate(arr: any = []) {
        const formRef = this.$refs.formRef as ElForm;
        formRef.clearValidate(arr);
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo(num: number): number {
        return num;
    }
    private answerContent(value: any) {
        this.formData.description = value;
        this.clearValidate(['description']);
    }
    private created() {
        this.getIndustryTypes();
        this.getDiscountRules();
        if (this.id) {
            this.contentTitle = "修改应用";
            this.getGoodsInfo();
        } else {
            this.initUE = true;
        }
        if (this.pageType === 'info') {
            this.contentTitle = "查看应用";
        }
        this.$nextTick(() => {
            this.formRef = this.$refs.formRef;
        });
    }
    private getZipPath(path: string) {
        const arr = path.split('/');
        const fileName = arr[arr.length - 1];
        return fileName;
    }
    // 获取商品详情
    private getGoodsInfo() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/mall/goods/app/${this.id}`)
        .then((res: any) => {
            this.formData = res;
            if (res.functionMenuFilePath) {
                this.menuFileList = [{
                    name: this.getZipPath(res.functionMenuFilePath),
                    url: res.functionMenuFilePath,
                }];
            }
            this.initUE = true;
        });
    }
    // 获取行业类型
    private getIndustryTypes() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/config/industry-types`)
        .then((rep: any) => {
            this.industryTypes = rep;
        });
    }
    // 获取支付折扣规则
    private getDiscountRules() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/config/pay-discount-rules`)
        .then((rep: any) => {
            this.discountRules = rep;
        });
    }
    private filterPriceItem(data: any) {
        return data.map((item: any) => {
            if (!item.existFlag) {
                return {
                    ...item,
                    price: '',  // 价格
                };
            } else {
                return item;
            }
        });
    }
    private handleSave(status?: number) {
        const formRef: any = this.$refs.formRef;
        formRef.validate((valid: boolean) => {
            if (valid) {
                if (this.oracleUploading || this.oracleUploading) {
                    this.$message.error('应用包上传中，请稍候！');
                    return;
                }
                const formData: any = Object.assign({}, {
                    ...this.formData,
                    priceItems: this.filterPriceItem(this.formData.priceItems),
                });
                if (status === 0 || status === 1) {
                    formData.status = status;
                }
                if (formData.mcVersion === '4.0') {
                    if (!formData.databaseTypes.includes(1)) {
                        formData.pgPackageIdentifier = null;
                        formData.pgPackagePath = null;
                        formData.pgPackageVersion = null;
                        formData.pgPackageCreateTime = null;
                    }
                    if (!formData.databaseTypes.includes(2)) {
                        formData.oraclePackageIdentifier = null;
                        formData.oraclePackagePath = null;
                        formData.oraclePackageVersion = null;
                        formData.oraclePackageCreateTime = null;
                    }
                }
                if (this.id) {
                    this.handleUpdate(formData);
                } else {
                    this.handleAdd(formData);
                }
            } else {
                this.$message.error('请检查表单是否完善！');
            }
        });
    }
    // 添加
    private handleAdd(formData: any) {
        this.$httpService.putData(formData, '/apiProxy/api/frontend/mall/goods/app')
        .then((res: any) => {
            this.$message.success('提交成功！');
            this.closeTodo(3);
        }).catch((err: any) => {
            // if (err.code === 1004013001) {
            //     this.projectNameError = '此项目名称已使用过，请勿重复申请！';
            // }
        });
    }
    // 修改
    private handleUpdate(formData: any) {
        this.$httpService.postData(formData, `/apiProxy/api/frontend/mall/goods/app/${this.id}`)
        .then((res: any) => {
            this.$message.success('修改成功！');
            this.closeTodo(3);
        }).catch((err: any) => {
            // if (err.code === 1004013001) {
            //     this.projectNameError = '此项目名称已使用过，请勿重复申请！';
            // }
        });
    }
    private propGoBlack() {
        this.closeTodo(1);
    }
    //  下载附件
    private async downloadFile(fileUrl: any, fileName: any) {
        this.$message.success('文件下载中，请稍等...');
        const response = await fetch(this.baseUrl  + fileUrl);
        const blob = await response.blob();
        this.triggerDownload(URL.createObjectURL(blob), fileName);
    }
    private triggerDownload(blob: any, fileName: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = blob;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
    }
    // 添加应用icon、规格icon
    private beforeUploadIcon(file: any) {
        const fileType = file.type;
        const fileName = file.name;
        // 根据后缀判断文件类型
        let fileSuffix = "";
        try {
            const fileArr = fileName.split(".");
            fileSuffix = fileArr[fileArr.length - 1];
        } catch (err) {
            fileSuffix = "";
        }
        const isCorrect = (
            fileType === 'image/png'
            || fileType === 'image/jpg'
            || fileType === 'image/jpeg'
        );
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            this.$message.error('上传文件大小不能超过 1MB!');
            return false;
        }
        if (!isCorrect) {
            this.$message.error('上传图片格式错误!');
            return false;
        }
    }
    private uploadIcon(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.formData.icon = res.path;
            this.clearValidate(['icon']);
            // this.fileName = data.file.name;
        }).catch(() => {
            // this.file = null;
            // this.fileList = [];
        });
    }
    // 售价类型下拉框disabled
    private disabledOption(type: any, val: any) {
        if (type === val) {
            return false;
        }
        return this.formData.priceItems.findIndex((item: any) => item.type === val) >= 0;
    }
    // 折扣规则
    private openDiscount(row: any, field: string, discountItems: any) {
        const formRef: any = this.$refs.formRef;
        formRef.validateField(field, (valid: boolean) => {
            if (!valid) {
                // if (!row.price.trim()) {
                //     this.$message.warning('价格不能为空');
                //     return;
                // }
                if (row.type === 2) {
                    this.discountOptions = this.discountRules.monthlyList.filter((item: any) => {
                        return this.monthlyList(discountItems)
                            .findIndex((col: any) => col.number === item.number) < 0;
                    });
                } else if (row.type === 3) {
                    this.discountOptions = this.discountRules.yearlyList.filter((item: any) => {
                        return this.yearlyList(discountItems)
                            .findIndex((col: any) => col.number === item.number) < 0;
                    });
                }
                this.discountInfo = Object.assign({}, row);
                this.discountVisible = true;
            }
        });
    }
    private closeDiscount(data: any) {
        const {num, obj} = data;
        if (num === 3) {
            this.formData.discountItems.push(obj);
        }
        this.discountVisible = false;
    }
    private handleRemoveDiscount(tag: any) {
        this.formData.discountItems = this.formData.discountItems.filter((item: any) => {
            return item.number !== tag.number;
        });
    }
    // 上传应用包
    private pgAppChange(res: any) {
        this.formData.pgPackageIdentifier = res.data.packageIdentifier;
        this.formData.pgPackageVersion = res.data.packageVersion;
        this.formData.pgPackagePath = res.data.packagePath;
        this.formData.pgPackageCreateTime = res.data.packageCreateTime;
        this.pgError = res.errMsg;
        this.pgUploading = res.status === 'uploading';
    }
    private oracleAppChange(res: any) {
        this.formData.oraclePackageIdentifier = res.data.packageIdentifier;
        this.formData.oraclePackageVersion = res.data.packageVersion;
        this.formData.oraclePackagePath = res.data.packagePath;
        this.formData.oraclePackageCreateTime = res.data.packageCreateTime;
        this.oracleError = res.errMsg;
        this.oracleUploading = res.status === 'uploading';
    }
    // 套件包功能清单
    private chooseMenuFile(event: any) {
        this.menuFileList = [event.file];
        this.isView = false;
        this.viewMenuFile(event.file);
    }
    private async handleMenuPriview(fileData: any) {
        const resp = await fetch(this.baseUrl  + this.formData.functionMenuFilePath);
        const blob = await resp.blob();
        const file = new File([blob], fileData.name, {type: blob.type});
        this.isView = true;
        this.viewMenuFile(file);
    }
    private async viewMenuFile(file: any) {
        const param = new FormData();
        param.append('file', file);
        param.append('name', file.name);
        this.$httpService.postData(param, '/apiProxy/api/frontend/mall/goods/fuction-menu/upload', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }).then((res: any) => {
            this.showDialog = true;
            this.$nextTick(() => {
                this.setFuctionMenu(res);
                this.formData.functionMenuFilePath = res.path;
                // const file: any = this.$refs.menuFile;
                // file.value = '';
            });
        });
    }
    private uploadMenuFile() {
        if (this.isView) {
            this.showDialog = false;
            return;
        }
        // this.formData.functionMenuFilePath = res.path;
        this.showDialog = false;
    }
    private handleMenuFile() {
        this.formData.functionMenuFilePath = '';
    }
    private handleRemoveMenuFile() {
        if (this.isView) {
            this.showDialog = false;
            return;
        }
        this.formData.functionMenuFilePath = '';
        this.menuFileList = [];
        this.showDialog = false;
    }
    private setFuctionMenu(dataObj: any) {
        if (!dataObj.fuctionList) {
            return;
        }
        this.menuList = dataObj.fuctionList;
    }
}
