
import '@/assets/css/iframeStyle.scss';
import { Component, Vue } from 'vue-property-decorator';
import AddApp from "./components/AddApp.vue";

@Component({
    name: "MyApp",
    components: {
        AddApp,
    },
})
export default class MyApp extends Vue {
    private contentTitle: string = "我的应用";
    private baseUrlIfram = process.env.VUE_APP_URL;
    private activePage = 'APPLIST';  // APPLIST:列表页；APPADD:添加应用；
    private appId: any = null;  // null;
    private appStatus: any = null;  // -1: 审核不通过；0: 编辑中；1: 待审核；2: 已上架；3: 已下架；
    private addPageType: string = 'add';
    private editApp(res: any) {
        if (res.data.pageSource === 'saleAppList') {
            this.appId = res.data.goodsId;
            this.appStatus = res.data.appStatus;
            this.activePage = 'APPADD';
        }
    }
    private closeAddApp(num: number) {
        if (num === 3) {
            const saleAppListIframe: any = this.$refs.saleAppList;
            saleAppListIframe.contentWindow.getAppList();
        }
        this.activePage = 'APPLIST';
    }
    private created() {
        window.addEventListener('message', this.editApp);
    }
}
